import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import Trans from "atoms/i18n/trans";
import UserRecord from "models/view-models/user-record";
import UserRoleGroupRecord from "models/view-models/user-role-group-record";
import MenuButton from "molecules/menu-button/menu-button";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import Tooltip, { TooltipPlacement } from "molecules/tooltips/tooltip";
import React, { useState } from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useLocalization } from "utilities/hooks/use-localization";
import { t } from "utilities/localization-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface UserRoleGroupRowProps {
    /**
     * The UserRoleGroup record of the current user viewing the team management page
     */
    currentUserRoleGroup: UserRoleGroupRecord;

    /**
     * Callback to be executed when the delete action completes.
     */
    onDelete: () => void;

    /**
     * Callback to be executed when the update action completes.
     */
    onUpdate: () => void;

    /**
     * The UserRoleGroup being displayed.
     */
    userRoleGroup: UserRoleGroupRecord;
    allowEdits?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserRoleGroupRow: React.FunctionComponent<UserRoleGroupRowProps> = (
    props: UserRoleGroupRowProps
) => {
    const {
        currentUserRoleGroup,
        onDelete,
        onUpdate,
        userRoleGroup,
        allowEdits = true,
    } = props;
    const { t } = useLocalization();
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
        useState(false);
    const { isOnline } = useNetworkInformation();
    const { bypassHermes } = useFeatureFlags();

    const user = userRoleGroup.userRole?.user ?? new UserRecord();
    const displayName = getDisplayName(currentUserRoleGroup, userRoleGroup);
    const removeOrUpdateDisabled = currentUserRoleGroup.id === userRoleGroup.id;

    const showDropdown = currentUserRoleGroup.isAdmin && allowEdits;

    const dropdownMenuAccessibleText = t("actionsForItem", {
        item: t("team-member"),
    });
    const removeFromTeamText = t("removeFromTeam");
    const setTypeText = t("setAsRole", {
        role: userRoleGroup.isAdmin ? t("team-member") : t("admin"),
    });

    const ActionsDropdown = (
        <div>
            <Tooltip
                placement={TooltipPlacement.Top}
                content={t("bypassHermes-feature-unavailable-tooltip")}
                disabled={!bypassHermes || !isOnline}
                durationInMs={0}
                hideOnClick={true}>
                <div>
                    <MenuButton
                        buttonAccessibleText={dropdownMenuAccessibleText}
                        offlineAlertOptions={{
                            enabled: true,
                            tooltipHeader: dropdownMenuAccessibleText,
                            tooltipDescription: t(
                                "offline-fallback-description"
                            ),
                            tooltipPlacement: TooltipPlacement.Top,
                        }}
                        disabled={bypassHermes && isOnline}
                        triggerButtonSize={ButtonSizes.Small}>
                        <Button
                            disabled={removeOrUpdateDisabled}
                            onClick={onUpdate}
                            style={ButtonStyles.Tertiary}>
                            {setTypeText}
                        </Button>
                        <Button
                            disabled={removeOrUpdateDisabled}
                            onClick={() => setIsConfirmationModalOpen(true)}
                            style={ButtonStyles.Tertiary}>
                            {removeFromTeamText}
                        </Button>
                    </MenuButton>
                </div>
            </Tooltip>
        </div>
    );

    return (
        <tr>
            <td>{displayName}</td>
            <td>{user.email}</td>
            <td>{userRoleGroup.getLastActive() ?? "N/A"}</td>
            <td>{userRoleGroup.getTypeName()}</td>
            <td>
                {showDropdown && (
                    <React.Fragment>{ActionsDropdown}</React.Fragment>
                )}
            </td>
            {isConfirmationModalOpen && (
                <ConfirmationModal
                    confirmButtonText={t("yesAction", {
                        action: removeFromTeamText,
                    })}
                    isVisible={true}
                    message={
                        <span>
                            <Trans i18nKey="confirmRemoveUserFromTeam">
                                Are you sure you want to remove{" "}
                                <strong>
                                    <>{{ user: user.getFirstAndLastName() }}</>
                                </strong>{" "}
                                from your team?
                            </Trans>
                        </span>
                    }
                    onCancel={() => setIsConfirmationModalOpen(false)}
                    onConfirm={() => {
                        setIsConfirmationModalOpen(false);
                        // Let the parent component know we are confirming this delete action.
                        onDelete();
                    }}
                />
            )}
        </tr>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getDisplayName = (
    currentUserRoleGroup: UserRoleGroupRecord,
    userRoleGroup: UserRoleGroupRecord
): string => {
    const user = userRoleGroup?.userRole?.user;
    let name = user?.getFirstAndLastName() ?? "--";
    if (userRoleGroup.id === currentUserRoleGroup.id) {
        name = `${name} (${t("you")})`;
    }

    return name;
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default UserRoleGroupRow;

// #endregion Export
