import Loader from "atoms/loaders/loader";
import { siteMap } from "internal-sitemap";
import GroupWithPurchaserRecord from "models/view-models/group-with-purchaser-record";
import DataTable from "organisms/data-tables/data-table";
import React from "react";
import { useHistory } from "react-router";
import { CollectionUtils } from "utilities/collection-utils";
import { RouteUtils } from "utilities/route-utils";

export interface TeamsAdminTableProps {
    loading: boolean;
    teams: Array<GroupWithPurchaserRecord>;
}

const CSS_BASE_CLASS = "c-support-admin-dashboard-table";

const TeamsAdminTable: React.FC<any> = (props: TeamsAdminTableProps) => {
    const { loading, teams } = props;

    const history = useHistory();

    if (loading) {
        return <Loader accessibleText={"Loading Teams..."} />;
    }

    const handleClick = (path: string) => history.push(path);

    return (
        <DataTable cssClassName={CSS_BASE_CLASS}>
            <thead>
                <tr>
                    <th>Admin</th>
                    <th>Team Name</th>
                    <th>Subscription Type</th>
                </tr>
            </thead>
            <tbody>
                {!loading && CollectionUtils.isNotEmpty(teams) ? (
                    teams.map((team: GroupWithPurchaserRecord) => {
                        const path = RouteUtils.getUrl(
                            siteMap.admin.teams.detail,
                            { id: team.id }
                        );

                        return (
                            <tr
                                className={`${CSS_BASE_CLASS}__row`}
                                key={team.id}
                                onClick={() => handleClick(path)}>
                                <td>{team.groupPurchaser?.email}</td>
                                <td>{team.name}</td>
                                <td>{team.subscriptionType}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={4} className="-no-results-row">
                            No results found - please try adjusting your search.
                        </td>
                    </tr>
                )}
            </tbody>
        </DataTable>
    );
};

export default TeamsAdminTable;
