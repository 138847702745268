import Button from "atoms/buttons/button";
import React from "react";
import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import { AuthenticationStrategyType } from "utilities/enumerations/authorization";
import { ButtonStyles } from "atoms/constants/button-styles";
import { t } from "utilities/localization-utils";
import { useAuthentication } from "utilities/contexts/authentication/authentication-provider";

interface LogInAnchorProps {
    buttonText?: string;
    cssClassName?: string;
    lowerCase?: boolean;
    style?: ButtonStyles;
    target?: AnchorTargetTypes;
}

const LogInAnchor: React.FC<LogInAnchorProps> = ({
    buttonText,
    cssClassName = "c-button -anchor",
    style,
}) => {
    const { login } = useAuthentication();

    return (
        <Button
            onClick={() => login(AuthenticationStrategyType.AzureB2C)}
            cssClassName={cssClassName}
            style={style}>
            {buttonText ?? t("logIn")}
        </Button>
    );
};

export default LogInAnchor;
