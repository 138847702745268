import { ToolbarConfig } from "react-rte";

const MAX_LENGTH = 5000;
const RICH_TEXT_TOOLBAR_CONFIG: Partial<ToolbarConfig> = {
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD" },
        { label: "Italic", style: "ITALIC" },
        { label: "Strikethrough", style: "STRIKETHROUGH" },
        { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: "Unordered List", style: "unordered-list-item" },
        { label: "Ordered List", style: "ordered-list-item" },
    ],
};

const UserBookmarkConstants = {
    MAX_LENGTH,
    RICH_TEXT_TOOLBAR_CONFIG,
};

export default UserBookmarkConstants;
