import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React from "react";

const TeamsAdminBreadCrumb: React.FC = () => (
    <Breadcrumb>
        <span className="c-breadcrumb__label">LiNK Teams</span>
    </Breadcrumb>
);

export default TeamsAdminBreadCrumb;
