import { useEffect, useCallback } from "react";
import useIdentity from "utilities/hooks/use-identity";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import GlobalStateRecord from "models/view-models/global-state-record";
import { atom, useAtom } from "jotai";

/**
 * Custom hook to refresh the identity in globalState, with callback to trigger additional refreshes
 * on command.
 */
const loadingAtom = atom(false);
const loadedAtom = atom(false);

export default function useRefreshIdentity() {
    const { globalState, setGlobalState } = useGlobalState();
    const { buildCurrentIdentity } = useIdentity();

    const [loading, setLoading] = useAtom(loadingAtom);
    const [loaded, setLoaded] = useAtom(loadedAtom);

    const updateIdentity = useCallback(
        async (forceRefresh = false) => {
            if ((loaded || loading) && !forceRefresh) {
                return;
            }

            try {
                setLoading(true);

                if (globalState.currentIdentity?.userLogin === undefined) {
                    return;
                }
                // console.log(
                //     "***buildCurrentIdentity called in use-refresh-identity.ts"
                // );
                const identity = await buildCurrentIdentity(
                    globalState.currentIdentity?.userLogin
                );
                // console.log(
                //     "***buildCurrentIdentity Completed in use-refresh-identity.ts"
                // );

                if (identity == null) {
                    return;
                }

                setGlobalState((globalState: GlobalStateRecord) =>
                    globalState.setIdentity(identity)
                );
            } catch (error) {
                console.error("use-refresh-identity error: ", error);
            } finally {
                setLoaded(true);
                setLoading(false);
            }
        },
        [
            buildCurrentIdentity,
            globalState.currentIdentity?.userLogin,
            loaded,
            loading,
            setGlobalState,
            setLoaded,
            setLoading,
        ]
    );

    useEffect(() => {
        // console.log("Calling updateIdentity");
        updateIdentity();
    }, [updateIdentity]);

    return { loading, refresh: updateIdentity, setLoading };
}
