import AccountDisplayInformation from "pages/account/tabs/tab-panels/account-information/account-display-information";
import AccountLanguagePreferences from "pages/account/tabs/tab-panels/account-information/account-language-preferences";
import Anchor from "atoms/anchors/anchor";
import Heading from "atoms/typography/heading";
import React from "react";
import StringUtils from "utilities/string-utils";
import UserWorkGroupsList from "organisms/publication-content/user-workgroups/user-workgroups-list";
import useAccountInformationTab from "pages/account/tabs/tab-panels/use-account-information-tab";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import useNfpaUrlPaths from "utilities/hooks/use-nfpa-url-paths";
import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AccountInformationTabPanelProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-account-dashboard__information";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AccountInformationTabPanel: React.FC<
    AccountInformationTabPanelProps
> = () => {
    const { globalState } = useGlobalState();
    const { t } = useLocalization();
    const { isOnline } = useNetworkInformation();
    const { nfpaMyProfileUrl } = useNfpaUrlPaths();
    const {
        accountInfoLabel,
        email,
        emailLabel,
        emailNotAvailable,
        fullName,
        nameLabel,
        nameNotAvailable,
    } = useAccountInformationTab();
    const isEnterprise = globalState.currentIdentity
        ?.getCurrentUserRole()
        ?.isEnterpriseRole();

    const password = UnicodeCharacterConstants.Bullet.repeat(12);

    return (
        <div className={BASE_CLASS}>
            <div className={`${BASE_CLASS}__personal`}>
                <Heading priority={HeadingPriority.Five}>
                    {accountInfoLabel}
                </Heading>
                <AccountDisplayInformation
                    heading={nameLabel}
                    information={
                        StringUtils.isEmpty(fullName)
                            ? nameNotAvailable
                            : fullName!
                    }
                />
                <AccountDisplayInformation
                    heading={emailLabel}
                    information={
                        StringUtils.isEmpty(email) ? emailNotAvailable : email!
                    }
                />
                <AccountDisplayInformation
                    heading={t("password")}
                    information={password}
                />
                <Anchor
                    ariaLabel={t("accountDashboard-manageAccount")}
                    disabled={!isOnline}
                    external={true}
                    target={AnchorTargetTypes.Blank}
                    to={nfpaMyProfileUrl}>
                    {t("accountDashboard-manageAccount")}
                </Anchor>
            </div>
            <AccountLanguagePreferences />
            {isEnterprise && (
                <div className={`${BASE_CLASS}__workgroups`}>
                    <UserWorkGroupsList />
                </div>
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AccountInformationTabPanel;

// #endregion Exports
