import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import GroupPurchaser from "models/interfaces/group-purchaser";

const defaultValues: GroupPurchaser =
    RecordUtils.defaultValuesFactory<GroupPurchaser>({
        userId: undefined,
        externalId: undefined,
        fullName: undefined,
        email: undefined,
    });

export default class GroupPurchaserRecord
    extends Record(defaultValues)
    implements GroupPurchaser
{
    constructor(params?: GroupPurchaser) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    public with(values: Partial<GroupPurchaser>): GroupPurchaserRecord {
        return new GroupPurchaserRecord(Object.assign(this.toJS(), values));
    }
}
