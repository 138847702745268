import { RecordUtils } from "@rsm-hcd/javascript-core";
import { Record } from "immutable";
import GroupWithPurchaser from "models/interfaces/group-with-purchaser";
import GroupPurchaserRecord from "./group-purchaser-record";

const defaultValues: GroupWithPurchaser =
    RecordUtils.defaultValuesFactory<GroupWithPurchaser>({
        id: undefined,
        externalId: undefined,
        name: undefined,
        subscriptionType: undefined,
        groupPurchaser: undefined,
    });

export default class GroupWithPurchaserRecord
    extends Record(defaultValues)
    implements GroupWithPurchaser
{
    constructor(params?: GroupWithPurchaser) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.groupPurchaser) {
            params.groupPurchaser = RecordUtils.ensureRecord(
                params.groupPurchaser,
                GroupPurchaserRecord
            );
        }

        super(params);
    }

    public with(values: Partial<GroupWithPurchaser>): GroupWithPurchaserRecord {
        return new GroupWithPurchaserRecord(Object.assign(this.toJS(), values));
    }
}
