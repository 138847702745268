import Button from "atoms/buttons/button";
import { Icons } from "atoms/constants/icons";
import SubscriptionTypeLabel from "molecules/subscription-type-label/subscription-type-label";
import Tooltip from "molecules/tooltips/tooltip";
import SubscriptionType from "organisms/enums/subscription-type";
import * as React from "react";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";
import useFeatureFlags from "utilities/hooks/use-feature-flags";

interface SubscriptionCardProps {
    billingText: string;
    buttonText: string;
    headingIcon: Icons;
    onSelect: () => void;
    price: string;
    type: SubscriptionType;
    cssClassNameModifier?: string;
    children?: React.ReactNode;
}

const SubscriptionCard: React.FunctionComponent<SubscriptionCardProps> = (
    props
) => {
    const { pricingUpdate } = useFeatureFlags();
    const baseClass = "c-subscription-card";
    const { subscriptionFreezeActive, alertMessageContents } =
        useConfigurableAlertMessages();

    return (
        <div
            className={`${baseClass} ${props.cssClassNameModifier ?? ""}`}
            data-test-id={props.type}>
            <div className={baseClass + "__header"}>
                <div className={baseClass + "__header__title"}>
                    <SubscriptionTypeLabel type={props.type} />
                </div>
                {!pricingUpdate && (
                    <div className={baseClass + "__header__cost"}>
                        <div>{props.price}</div>
                        <div>{props.billingText}</div>
                    </div>
                )}
            </div>
            <div className={baseClass + "__button-wrapper"}>
                <Tooltip
                    content={alertMessageContents?.tooltip}
                    disabled={!subscriptionFreezeActive}>
                    <Button
                        onClick={
                            !subscriptionFreezeActive
                                ? props.onSelect
                                : () => {}
                        }>
                        {props.buttonText}
                    </Button>
                </Tooltip>
            </div>
            <div className={baseClass + "__content"}>{props.children}</div>
        </div>
    );
};

export default SubscriptionCard;
