import { siteMap } from "internal-sitemap";
import TeamManagementArea from "pages/account/team-management/team-management-area";
import React from "react";
import { Redirect } from "react-router-dom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TeamManagementTabPanelProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TeamManagementTabPanel: React.FC<TeamManagementTabPanelProps> = () => {
    const { globalState } = useGlobalState();

    if (!globalState.currentIdentity?.isGroupMember()) {
        return <Redirect to={siteMap.account.information} />;
    }
    return <TeamManagementArea />;
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default TeamManagementTabPanel;

// #endregion Exports
