import GroupRecord from "models/view-models/group-record";
import SupportUserRecord from "models/view-models/support-user-record";
import UserRoleGroupRecord from "models/view-models/user-role-group-record";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DateUtils from "utilities/date-utils";
import useSubscription from "utilities/hooks/domain/subscriptions/use-subscription";
import { useEcommercePrices } from "utilities/hooks/use-ecommerce-prices";
import GroupService from "utilities/services/groups/group-service";
import SupportUserService from "utilities/services/support/support-users-service";
import UserRoleGroupService from "utilities/services/user-role-groups/user-role-group-service";
import UserRoleService from "utilities/services/users/user-roles/user-role-service";
import UserService from "utilities/services/users/user-service";
interface Params {
    id: string;
}
const useAdminTeamDetails = () => {
    const { id: paramId } = useParams<Params>();
    const { get: getGroup } = GroupService.useGet();
    const { get: getUser } = UserService.useGet();
    const { get: getUserRole } = UserRoleService.useGet();
    const { list: listUserRoleGroups } = UserRoleGroupService.useList();
    const [team, setTeam] = useState<GroupRecord>();
    const [userRoleGroup, setUserRoleGroup] = useState<UserRoleGroupRecord>();
    const [subscriptionOwner, setSubscriptionOwner] =
        useState<SupportUserRecord>();
    const [subscriptionId, setSubscriptionId] = useState<number>();
    const [expirationDate, setExpirationDate] = useState("");

    const { fromPin } = useEcommercePrices();
    const { resultObject: subscription } = useSubscription(subscriptionId);
    const { cycle, autoRenew } = fromPin(subscription.sku) ?? {};

    // Get Team based on id param
    useEffect(() => {
        const getTeam = async () => {
            const id = Number(paramId);
            const response = await getGroup({ id });
            setTeam(response.resultObject);
        };
        getTeam();
    }, [getGroup, paramId]);

    // Get User (for email, name, etc.)
    useEffect(() => {
        const getSubscriptionCreator = async () => {
            const id = team?.createdById;
            if (id) {
                const response = await SupportUserService.get({ id });
                setSubscriptionOwner(response.resultObject);
            }
        };
        getSubscriptionCreator();
    }, [getUser, paramId, team?.createdById]);

    // Gets User Role Groups, Subscription
    useEffect(() => {
        const getUserRoleGroups = async () => {
            const userId = team?.createdById;
            if (userId) {
                const groupRes = await listUserRoleGroups({
                    userId,
                    groupId: team?.id,
                });
                setUserRoleGroup(groupRes.resultObjects.find(Boolean));
                const userRoleId = groupRes.resultObjects.pop()?.userRoleId;
                if (userRoleId) {
                    const roleRes = await getUserRole({
                        id: userRoleId,
                        userId,
                    });
                    setSubscriptionId(roleRes.resultObject?.subscriptionId);
                    setExpirationDate(
                        DateUtils.formatDate(roleRes.resultObject?.expiresOn)
                    );
                }
            }
        };

        getUserRoleGroups();
    }, [getUserRole, listUserRoleGroups, team?.createdById, team?.id]);

    return {
        subscriptionOwner,
        team,
        subscription,
        cycle,
        autoRenew,
        expirationDate,
        userRoleGroup,
    };
};

export default useAdminTeamDetails;
