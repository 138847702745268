import BillingCycleRadioButtons from "organisms/subscription-card/billing-cycle-radio-buttons";
import Button from "atoms/buttons/button";
import Paragraph from "atoms/typography/paragraph";
import PlansAnchor from "molecules/plans-anchor/plans-anchor";
import React, { useMemo, useState } from "react";
import SubscriptionFeatureList from "organisms/subscription-card/subscription-feature-list";
import SubscriptionType from "organisms/enums/subscription-type";
import SubscriptionTypeLabel from "molecules/subscription-type-label/subscription-type-label";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import useNfpaUrlPaths from "utilities/hooks/use-nfpa-url-paths";
import { BillingCycleOption } from "constants/billing-cycle-options";
import { ButtonStyles } from "atoms/constants/button-styles";
import { EcommercePins } from "constants/ecommerce-pins";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import { SubscriptionFeaturesType } from "utilities/hooks/domain/subscriptions/use-subscription-features";
import { t } from "utilities/localization-utils";
import { useEcommercePrices } from "utilities/hooks/use-ecommerce-prices";

interface SubscriptionChoiceCardProps {
    subscriptionType: SubscriptionType;
}
const BASE_CLASS = "c-subscription-choice-card";

const SubscriptionChoiceCard: React.FunctionComponent<
    SubscriptionChoiceCardProps
> = (props: SubscriptionChoiceCardProps) => {
    const { subscriptionType } = props;
    const { billingCycleOptions } = useEcommercePrices();
    const { planOptions } = useNfpaUrlPaths();

    const initialState = billingCycleOptions.individual.find(
        (o: BillingCycleOption) =>
            o.pin === EcommercePins.IndividualAnnualAutoRenew
    );
    const [redirecting, setRedirecting] = useState(false);

    const { isOnline } = useNetworkInformation();

    const headerLabel = t("subscription-choice-card-header");
    const allPlansLabel = t("subscription-choice-card-plans-linktext");
    const buttonText = t("subscription-choice-card-buttontext");

    const featuresType = useMemo(() => {
        switch (subscriptionType) {
            case SubscriptionType.Individual:
            case SubscriptionType.FreeTrial:
                return SubscriptionFeaturesType.Individual;
            case SubscriptionType.Team:
                return SubscriptionFeaturesType.Team;
            case SubscriptionType.Enterprise:
                return SubscriptionFeaturesType.Enterprise;
            default:
                return SubscriptionFeaturesType.Individual;
        }
    }, [subscriptionType]);

    const [selectedOption, setSelectedOption] = useState<BillingCycleOption>(
        initialState!
    );

    const handleCheck = (option: BillingCycleOption) =>
        setSelectedOption(option);

    const handleUpgradeClick = () => {
        if (selectedOption == null) {
            // Add validation NFPA-4738
            return;
        }
        setRedirecting(true);
    };

    if (redirecting) {
        window.location.href = planOptions;
    }

    return (
        <div className={BASE_CLASS}>
            <Paragraph size={ParagraphSizes.Large}>{headerLabel}</Paragraph>
            {/* <Paragraph size={ParagraphSizes.Small}>
                    Some marketing copy highliting the benefits customers get
                    for this specific upgrade
                </Paragraph> */}
            <div className={`${BASE_CLASS}__plan`}>
                <SubscriptionTypeLabel
                    type={subscriptionType}
                    headingPriority={HeadingPriority.Five}
                    iconSize={IconSizes.Medium}
                />
                <BillingCycleRadioButtons
                    disabled={!isOnline}
                    includePrice={false}
                    inline={true}
                    onCheck={handleCheck}
                    type={subscriptionType}
                    value={selectedOption}
                />
                <span className={`${BASE_CLASS}__plan__price`}>
                    {selectedOption.price}
                </span>
                <span
                    className={`${BASE_CLASS}__plan__cycle`}>{`/ ${selectedOption.cycle}`}</span>
                <Paragraph size={ParagraphSizes.XSmall}>
                    {selectedOption.description}
                </Paragraph>

                <SubscriptionFeatureList type={featuresType} />
            </div>
            <div className={`${BASE_CLASS}__action`}>
                <PlansAnchor children={allPlansLabel} cssClassName="" />
                <span className={`${BASE_CLASS}__action__separator`}>
                    {t("or")}
                </span>
                <Button
                    disabled={!isOnline}
                    style={ButtonStyles.Primary}
                    onClick={handleUpgradeClick}>
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

export default SubscriptionChoiceCard;
