import { PagedQuery } from "@rsm-hcd/javascript-core";
import { ServiceFactory } from "@rsm-hcd/javascript-react";
import GroupWithPurchaserRecord from "models/view-models/group-with-purchaser-record";
import SearchQuery from "utilities/interfaces/search-query";

export interface GroupWithAdminsListQueryParams
    extends SearchQuery,
        PagedQuery {}

const baseEndpoint = "admin/teams";

const resourceType = GroupWithPurchaserRecord;

const TeamsAdminService = {
    list: ServiceFactory.list<
        GroupWithPurchaserRecord,
        GroupWithAdminsListQueryParams
    >(resourceType, baseEndpoint),
};

export default TeamsAdminService;
