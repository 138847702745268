import { SkipNavContent } from "@reach/skip-nav";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import React from "react";
import { RouteDefinition } from "utilities/interfaces/route-definition";
import { RouteUtils } from "utilities/route-utils";
import {
    NestedRoutes,
    NestedRoutesProps,
} from "utilities/routing/nested-route";

const BASE_CLASS = "c-support-admin-dashboard-layout";

const TeamsAdminLayout: React.FC<any> = (props: NestedRoutesProps) => {
    return (
        <>
            <BreadcrumbsMenu fullWidth />
            <SkipNavContent tabIndex={-1}>
                <main className={BASE_CLASS}>
                    <div className={`${BASE_CLASS}__heading`}>
                        <Heading priority={HeadingPriority.Four}>Teams</Heading>
                    </div>
                    <NestedRoutes
                        routes={props.routes.map((route: RouteDefinition) =>
                            RouteUtils.withCulture<RouteDefinition>(route)
                        )}
                    />
                </main>
            </SkipNavContent>
        </>
    );
};

export default TeamsAdminLayout;
