import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React from "react";

const TeamsAdminDetailBreadCrumb: React.FC = () => (
    <Breadcrumb>
        <span className="c-breadcrumb__label">Team</span>
    </Breadcrumb>
);

export default TeamsAdminDetailBreadCrumb;
