import { ResultRecord } from "@rsm-hcd/javascript-core";
import GroupWithPurchaserRecord from "models/view-models/group-with-purchaser-record";
import { useCallback, useState } from "react";
import usePagedResults from "utilities/hooks/use-paged-results";
import TeamsAdminService, {
    GroupWithAdminsListQueryParams,
} from "utilities/services/teams/teams-admin-service";
import { ToastManager } from "utilities/toast/toast-manager";

export default function useTeamsAdmin() {
    const DEFAULT_TAKE_SIZE = 10;
    const [teams, setTeams] = useState<GroupWithPurchaserRecord[]>([]);
    const [loadingTeams, setLoadingTeams] = useState(false);

    const {
        currentPage,
        numberOfPages,
        onPageSizeChange,
        onSelectPage,
        setRowCount,
        rowCount,
        take,
        onPageLast,
        onPageNext,
        skip,
        resetPagination,
    } = usePagedResults(DEFAULT_TAKE_SIZE);

    const resetPage = () => {
        resetPagination();
        setRowCount(0);
        setTeams([]);
    };

    const loadTeams = useCallback(
        async (params: GroupWithAdminsListQueryParams) => {
            setLoadingTeams(true);
            try {
                const results = await TeamsAdminService.list(params);
                setRowCount(results.rowCount);
                setTeams(results.resultObjects);
            } catch (error) {
                if (error instanceof ResultRecord) {
                    ToastManager.error(error.listErrorMessages());
                }
            } finally {
                setLoadingTeams(false);
            }
        },
        [setTeams, setRowCount]
    );

    return {
        loadingTeams,
        teams,
        loadTeams,
        rowCount,
        take,
        skip,
        currentPage,
        numberOfPages,
        onPageSizeChange,
        onSelectPage,
        onPageLast,
        onPageNext,
        resetPage,
    };
}
